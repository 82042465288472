import React from 'react';
import '../styles/app.scss';
import Routes from "./Routes/Routes";


function App() {
    return (
        <div className="App">
           <Routes/>
        </div>
    );
}

export default App;
